import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './App.css';

import Home from './seller_assets/home.png';
import Logo from './seller_assets/logo.png';
import Img1 from './seller_assets/img1.png';
import Img2 from './seller_assets/img2.png';
import Img3 from './seller_assets/img3.png';
import Img4 from './seller_assets/img4.png';
import Img5 from './seller_assets/img5.png';
import Mail from './seller_assets/mail.png';
import Vignette1 from './seller_assets/vignette1.png';
import Vignette2 from './seller_assets/vignette2.png';
import Vignette3 from './seller_assets/vignette3.png';
import Vignette4 from './seller_assets/vignette4.png';
import Vignette5 from './seller_assets/vignette5.png';
import Vignette6 from './seller_assets/vignette6.png';
import Circle from './seller_assets/circle.png';



const COLORS = {
  mainColor: '#002547',
  secondaryColor: '#f90000',
};



export default class CustomerPortal extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }


  componentDidMount() {
  }

  goTo(page) {
    window.location = "https://qorner.com/"+ page;
  }

  render() {


      const button = <div onClick={() => this.goTo("register")} className="buttonContainer" style={{backgroundColor: 'white', paddingRight: 24, paddingLeft: 24, paddingTop: 12, paddingBottom: 12, borderRadius: 200}}> <span style={{textTransform: 'uppercase', textDecoration: 'none', fontWeight: 'bold', color: COLORS.secondaryColor}}>Je REjoins Qorner !</span></div>;
      const button2 = <div onClick={() => this.goTo("contact")} className="buttonContainer2" style={{paddingRight: 24, paddingLeft: 24, paddingTop: 12, paddingBottom: 12}}> <span className="buttonContainer2Span" style={{fontSize: 12, textTransform: 'uppercase', textDecoration: 'none', fontWeight: 'bold', color: COLORS.secondaryColor}}>Je souhaite en savoir &#43;</span></div>;


      return (
        <Grid container>

          <Grid className="mainDiv" container style={{backgroundImage: `url(${Home})`,  backgroundSize: 'cover', backgroundColor: 'rgba(0,0,0,0.5)', paddingBottom: 120, paddingTop: 60}}>
            
            <Grid container style={{paddingBottom: 70}}>

              <Grid item xs={1} sm={1} md={2}>
              </Grid>

              <Grid item xs={10} sm={10} md={9}>
                <img style={{maxWidth: '100%'}} alt="logo" src={Logo} />
                <Typography variant="h3" style={{fontWeight: 'bold', color: 'white'}}>le nouvel allié des petits magasins : comment j’adhère et pourquoi je fonce !</Typography>
                <Typography variant="h5" align="justify" style={{fontStyle: 'italic', color: 'white'}}>Au carrefour de la nostalgie et de la modernité, les petits magasins sont là, bien là, toujours là. Qorner est un nouveau phare pour les éclairer !</Typography>
              </Grid>

              <Grid item xs={1} sm={1} md={1}>
              </Grid>

            </Grid>

            <Grid container>

              <Grid item xs={12} sm={12} md={6}>
                <Grid container>

                  <Grid item xs={1} sm={1} md={4}>
                  </Grid>

                  <Grid item xs={10} sm={10} md={6}>
                    <Typography align="justify" style={{color: 'white'}} variant="subtitle2">QORNER a clairement choisi sa mission : le soldat que nous voulons sauver, c’est <span style={{fontWeight: 'bold'}}>le petit commerçant !</span></Typography>
                    <br />
                    <Typography align="justify" style={{color: 'white'}} variant="subtitle2">Tous les services de notre application poursuivent un même objectif : encourager les <span style={{fontWeight: 'bold'}}>achats de proximité</span> dans les <span style={{fontWeight: 'bold'}}>commerces indépendants</span>.</Typography>
                    <br />
                  </Grid>

                  <Grid item xs={1} sm={1} md={2}>

                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>

                <Grid container>

                  <Grid item xs={1} sm={1} md={2}>
                  </Grid>

                  <Grid item xs={10} sm={10} md={6}>
                    <Typography align="justify" style={{color: 'white'}} variant="subtitle2">Votre boutique - oui, la vôtre ! - multiplie les atouts : l’accueil, la tradition, le sourire, le service, les conseils. Sans oublier le choix, la disponibilité et ce petit quelque chose qui fait toute la différence : <span style={{fontWeight: 'bold'}}>la touche d’humanité</span> entre celui qui vend et celui qui achète.</Typography>
                    <br />
                    <Typography align="justify" style={{color: 'white'}} variant="subtitle2">Si vous avez reconnu <span style={{fontWeight: 'bold'}}>votre commerce</span> dans ces quelques lignes, n’attendez pas : Qorner vous veut dans son équipe !</Typography>
                  </Grid>

                  <Grid item xs={1} sm={1} md={4}>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>


            <Grid container>

              <Grid item xs={1} sm={1} md={2}>
              </Grid>

              <Grid item xs={11} sm={11} md={4}>
                <Grid container md={12}>
                  <Grid style={{display: 'flex', marginTop: 30}} item xs={12} sm={12} md={6}>
                    { button }
                  </Grid>
                  <Grid  style={{display: 'flex', marginTop: 30}} item xs={12} sm={12} md={6}>
                    { button2 }
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={0} sm={0} md={4}>
              </Grid>

            </Grid>

          </Grid>

          <Grid container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid container xs={10} sm={10} md={4} style={{zIndex: 2, paddingTop: 60, paddingBottom: 40, justifyContent: 'flex-end'}}>
              <Grid item xs={12}>
                <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Je suis un petit commerçant, <span style={{color: COLORS.secondaryColor}}>expliquez-moi Qorner</span> !</Typography>
              </Grid>
              <Grid item xs={12}>
                <br />
                <Typography align="justify" variant="subtitle2">Commençons par le début : tous les consommateurs qui ont Qorner sur leur smartphone - c’est totalement <span style={{fontWeight: 'bold'}}>gratuit et sans engagement</span> - ont accès à <span style={{fontWeight: 'bold'}}>toutes les adresses</span> référencées dans l’application, <span style={{fontWeight: 'bold'}}>à tout moment</span> et <span style={{fontWeight: 'bold'}}>où qu’ils soient</span>.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">L’atout clé de QORNER, c’est <span style={{fontWeight: 'bold'}}>la localisation </span>! Sans vous connaître encore, les consommateurs qui vous entourent vous trouvent !</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Ils peuvent, quand ils le souhaitent, consulter votre vitrine virtuelle pour voir les articles que vous proposez : quels produits, quels prix, quels services, etc.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Bien installés dans leur cocon, vos futurs consommateurs ont tout le temps pour préparer leurs achats, vous localiser et passer à vos horaires d’ouverture pour les récupérer, les essayer, être conseillés et acheter plus si affinités ! QORNER <span style={{fontWeight: 'bold'}}>étend votre visibilité, votre clientèle, la fréquentation de votre commerce et le panier d’achat moyen !</span></Typography>
                <br />
              </Grid>
              <Grid item xs={0}>
              </Grid>
              <Grid  style={{display: 'flex', justifyContent: 'flex-end'}} item md={12}>
                { button2 }
              </Grid>
            </Grid>

            <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={12} sm={12} md={3}>
              <img style={{height: '90%', position: 'relative', top: -120}} alt="phone1" src={Img1} />
            </Grid>

            <Grid item xs={0} sm={0} md={3}>
            </Grid>

          </Grid>



          <Grid container>

            <Grid item xs={0} sm={0} md={2}>
            </Grid>

            <Grid item xs={12} sm={12} md={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img style={{height: '100%'}} alt="phone2" src={Img2} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>

                <Grid container  style={{height: '100%'}}>

                  <Grid item xs={1} sm={1} md={0}>
                  </Grid>

                  <Grid item xs={10} sm={10} md={8} style={{height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                    <div>
                      <Typography align="justify" variant="subtitle2">Sylvie vient de décrocher un nouveau poste. Sans perdre un instant, elle regarde déjà où elle pourra faire du lèche-vitrines dans son nouveau quartier à la pause déjeuner ! Votre boutique est à 200 mètres et vous avez des articles qu’elle trouve très sympas. C’est gagné : elle viendra vous voir et vous ajoutera directement dans ses favoris !</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Après une semaine à courir, Jérôme et Patricia se posent enfin dans leur canapé pour souffler. Lilie s’est enfin endormie. Zut ! Demain c’est l’anniversaire de sa copine Maëlle et ils n’ont pas de cadeau. Patricia a reçu votre notification sur ces jolis bâtons de pluie en bambou et autres produits que vous venez d’ajouter à votre catalogue : ce sera parfait ! Jérôme en réservera un sur son appli et ira le chercher demain quand il recevra votre confirmation.</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={1} sm={1} md={4}>
                  </Grid>

                </Grid>

            </Grid>

          </Grid>



          <Grid container style={{backgroundColor: 'white', paddingTop: 30}}>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid item xs={10} sm={10} md={4} style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>

              <div>
                <Typography align="justify" variant="subtitle2">Grâce à la <span style={{fontWeight: 'bold'}}>messagerie intégrée</span>, les clients peuvent dialoguer avec leur commerçant référencé pour demander un complément d’information sur un article, ou recevoir une confirmation de préparation de leur commande.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Ce cher Gaspard - qui ne veut pas débarquer chez ses amis à l’autre bout de la ville avec les mains vides - peut vous localiser en 3 clics à deux pas de son point de chute. Via QORNER, il dégotera la bonne adresse pour le cadeau qui fera mouche : les fleurs d’Élodie, la petite librairie Chez Marcel, les macarons de Julie, c’est par ici !</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Pour tous les petits magasins, ces ventes en <span style={{fontWeight: 'bold'}}>last minute</span> constituent un autre avantage.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Au-delà de la messagerie, l’appli permet aux commerçants d’envoyer des <span style={{fontWeight: 'bold'}}>notifications</span> pour <span style={{fontWeight: 'bold'}}>informer les clients</span> : week-end de promotion, arrivée d’une nouvelle collection, journée portes ouvertes, braderie, etc.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Non contente d’ouvrir une nouvelle porte et d’élargir votre cercle d’acheteurs, l’application Qorner vous offre l’opportunité de relayer vos <span style={{fontWeight: 'bold'}}>promotions</span> auprès d’une <span style={{fontWeight: 'bold'}}>cible conquise</span>.</Typography>
              </div>

            </Grid>


            <Grid item xs={12} sm={12} md={4} style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>

                  <Grid item xs={1} sm={1} md={2}>
                  </Grid>

                  <Grid item xs={10} sm={10} md={8} >
                    <img style={{height: '100%'}} alt="phone3" src={Img3} />
                  </Grid>

                  <Grid item xs={1} sm={1} md={2}>
                  </Grid>
              
            </Grid>

          </Grid>


          <Grid container style={{backgroundColor: 'white'}}>

            <Grid item xs={1} sm={1} md={2}></Grid>

            <Grid item xs={10} sm={10} md={4}>

              <div style={{justifyContent: 'center', display: 'flex', alignItems: 'center', padding: 20}}>
                {button}
              </div>

            </Grid>

            <Grid item xs={12} sm={12} md={4}></Grid>

          </Grid>


          <Grid container style={{backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={1} sm={1} md={2}></Grid>

            <Grid item xs={10} sm={10} md={4}>

              <img style={{width: '100%'}} alt="circle" src={Circle} />

            </Grid>

            <Grid item xs={12} sm={12} md={4}></Grid>

          </Grid>



          <Grid container style={{paddingTop: 30, paddingBottom: 30, backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={1} sm={1} md={3}>
            </Grid>

            <Grid item xs={10} sm={10} md={6}>
              <Typography variant="h4" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Chez Qorner, nous avons bien <span style={{color: COLORS.secondaryColor}}>compris votre message</span> !</Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={3}>

            </Grid>

          </Grid>





          <Grid container style={{paddingBottom: 30, backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={0} sm={0} md={2}>
            </Grid>

            <Grid item xs={12} sm={12} md={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'start'}}>
              <img style={{height: '80%'}} alt="phone2" src={Img4} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>

                <Grid container  style={{height: '100%'}}>

                  <Grid item xs={1} sm={1} md={0}>
                  </Grid>

                  <Grid item xs={10} sm={10} md={8} style={{height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>

                    <div>

                      <Typography align="justify" variant="subtitle2">Que voit-on quand on se place du côté des petits commerçants ?</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Élodie, Marcel et Julie n’ont que 2 mains pour taper sur 10 clous à la fois : gérer leur commerce, choisir les bons fournisseurs, sélectionner les produits, bichonner la vitrine, faire la promo, accueillir les clients, suivre la compta, zieuter le stock, s’afficher sur les réseaux, dompter les nouveaux outils, ... Sans parler du click & collect et des clients qui ne viennent plus en boutique !</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">À l’inverse des grandes chaînes et des franchises, les petites boutiques n’ont pas un spécialiste pour chacun de ces rôles.</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Il n’y a qu’une seule clé pour faire face à l’avalanche : un savant dosage de volonté, de polyvalence et d’énergie.</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">En conséquence, nos 3 charmants commerçants - j’adore tes macarons Julie - pensent à peu près ceci : « je veux bien m’intéresser à cette nouvelle idée, mais pitié, faites que ce ne soit pas chronophage » !</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Rassurez-vous : Qorner a anticipé ce message et quelques minutes suffisent <span style={{fontWeight: 'bold'}}>pour référencer votre magasin sur l’appli.</span></Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Nos conseillers vous expliqueront aussi :</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">- comment procéder pour <span style={{fontWeight: 'bold'}}>mettre vos articles en ligne</span> sur la plateforme ;</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">- comment rebondir astucieusement sur les adresses mails de vos clients pour <span style={{fontWeight: 'bold'}}>fidéliser vos contacts</span> ;</Typography>
                      <br />
                      <Typography style={{marginBottom: 50}} align="justify" variant="subtitle2">- comment <span style={{fontWeight: 'bold'}}>devenir un favori</span> sur Qorner pour jouir d’une visibilité optimale.</Typography>
                      
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div onClick={() => this.goTo("contact")} className="buttonContainer" style={{ border: "2px white solid", backgroundColor: COLORS.secondaryColor, paddingRight: 30, paddingLeft: 30, paddingTop: 15, paddingBottom: 15, borderRadius: 200}}>
                          <Typography align="center" variant="caption" style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'white'}}>Je souhaite un contact avec un conseiller Qorner</Typography>
                        </div>
                      </div>
                      

                    </div>

                  </Grid>

                  <Grid item xs={1} sm={1} md={4}>
                  </Grid>

                </Grid>

            </Grid>

          </Grid>





          <Grid container style={{paddingTop: 50}}>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid container xs={10} sm={10} md={6}>
              <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Une <span style={{color: COLORS.secondaryColor}}>visibilité dans l’air du temps</span>, j’en veux !</Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={4}>
            </Grid>

          </Grid>

          <Grid container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid container xs={10} sm={10} md={3} >

                <br />
                <Typography align="justify" variant="subtitle2">Avec une technologie up to date et une approche qui favorise les achats de proximité, Qorner s’inscrit <span style={{fontWeight: 'bold'}}>doublement dans l’air du temps.</span></Typography>
                <br />
                <Typography align="justify" variant="subtitle2">L’aventure commence aujourd’hui - fin 2021 - et nous avons hâte de recenser <span style={{fontWeight: 'bold'}}>votre commerce parmi nos partenaires.</span></Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Dans cette optique, voici concrètement ce que nous vous proposons :</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">- Pour toute <span style={{fontWeight: 'bold'}}>inscription d’un commerce ou d'un service</span>, les frais de dossier sont actuellement intégralement offerts.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">- Pour chaque magasin inscrit, <span style={{fontWeight: 'bold'}}>les 3 premiers mois</span> de l’abonnement sont également actuellement offerts.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">- Pour profiter de cette <span style={{fontWeight: 'bold'}}>action de lancement</span>, il suffit de contacter l’un de nos conseillers, à votre meilleure convenance, par <span style={{fontWeight: 'bold'}}>mail</span>.</Typography>
                <br />

            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <img style={{height: '100%'}} alt="phone5" src={Img5} />
            </Grid>

            <Grid item xs={0} sm={0} md={3}>
            </Grid>

          </Grid>

          <Grid container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid xs={10} sm={10} md={3} >

                <Grid style={{width: '100%', marginTop: 30, marginBottom: 30}} container>

                  <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} xs={12}>
                    <div onClick={() => this.goTo("contact")} className="buttonContainer" style={{ border: "2px white solid", backgroundColor: COLORS.secondaryColor, paddingRight: 15, paddingLeft: 15, paddingTop: 10, paddingBottom: 10, borderRadius: 200, alignItems: 'center'}}>
                      <Typography align="center" variant="caption" style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'white'}}>Je vous écris<img alt="write" style={{paddingLeft: 10, width: 15}} src={Mail} /></Typography>
                    </div>
                  </Grid>

                </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={4}>
            </Grid>

            <Grid item xs={0} sm={0} md={3}>
            </Grid>

          </Grid>

          <Grid container style={{backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid xs={10} sm={10} md={3} >

                <Grid style={{width: '100%'}} container>

                  <img style={{width: '100%'}} alt="circle" src={Circle} />

                </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={4}>
            </Grid>

            <Grid item xs={0} sm={0} md={3}>
            </Grid>

          </Grid>


          <Grid container style={{paddingTop: 60, paddingBottom: 30, backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={1} sm={1} md={0}>
            </Grid>

            <Grid item xs={10} sm={10} md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Comment ça marche pour <span style={{color: COLORS.secondaryColor}}>les clients</span>?</Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={0}>
            </Grid>

          </Grid>
          <Grid container style={{paddingBottom: 50, backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={1} sm={1} md={3}>
            </Grid>

            <Grid item xs={10} sm={10} md={6}>

              <Typography align="justify" variant="subtitle2">Côté consommateur, c’est simple comme bonjour. Il leur suffit de télécharger l’application et de créer un compte, gratuitement et sans engagement. C’est l’affaire de 2 minutes.</Typography>
              <Typography align="justify" variant="subtitle2">Dès ce moment, les Qorner-addicts auront accès à toutes les adresses des commerçants répertoriés, en fonction de leurs spécialités respectives : librairie, magasin déco, salon de coiffure, cordonnier, boutique de mode, toilettage pour toutous, …</Typography>
              <Typography align="justify" variant="subtitle2">Ils pourront donc localiser les magasins partenaires qui sont proches de leur domicile, de leur lieu de travail ou de n’importe quel endroit où ils comptent se rendre !</Typography>
              <Typography align="justify" variant="subtitle2">Quand ils trouveront le produit qu’ils recherchent en glissant un index curieux sur leur smartphone, ils n’auront plus besoin d’attendre une livraison en se demandant si le contenu du colis correspond bien à leurs attentes.</Typography>
              <Typography align="justify" variant="subtitle2">Ce n’est pas le bon livre ? Le pull est trop petit ? La moitié du flacon s’est évaporée ? Zut alors !</Typography>
              <Typography align="justify" variant="subtitle2">Dans votre boutique, la réponse est immédiate ! Si la robe convient, il suffit de passer à la caisse et de l’emporter, sans oublier d’avoir jeté un coup d’œil sur vos autres articles. Et si ce n’est pas tout à fait ce que Zoé recherchait, malgré vos conseils avisés, rien n’est mal fait : maintenant qu’elle sait où vous trouver …</Typography>
            
            </Grid>

            <Grid item xs={1} sm={1} md={3}>
            </Grid>

          </Grid>
          <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 30, backgroundColor: 'rgb(243, 242, 242)'}}>

            <div onClick={() => this.goTo("register")} className="buttonContainer" style={{ border: "2px white solid", backgroundColor: COLORS.secondaryColor, paddingRight: 30, paddingLeft: 30, paddingTop: 10, paddingBottom: 10, borderRadius: 200, alignItems: 'center'}}>
              <Typography align="center" variant="caption" style={{textTransform: 'uppercase', fontWeight: 'bold', color: 'white'}}>Je souhaite inscrire mon magasin sur QORNER !</Typography>
            </div>

          </Grid>



          <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 60, paddingBottom: 10, backgroundColor: 'white'}}>

            <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Un petit <span style={{color: COLORS.secondaryColor}}>résumé</span> pour conclure !</Typography>

          </Grid>
          <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 30, backgroundColor: 'white'}}>

            <Typography variant="subtitle1" style={{fontWeight: 'bold', color: COLORS.mainColor}}>En référençant mon magasin sur Qorner, je m’offre 6 atouts décisifs :</Typography>

          </Grid>



          <Grid container>

            <Grid item xs={0} sm={0} md={1}></Grid>

            <Grid item xs={12} sm={12} md={10}>
            
              <Grid container>

                <Grid style={{alignItems: 'center', justifyContent: 'center'}} item xs={6} sm={6} md={2}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '80%'}} alt="vignette1" src={Vignette1} />
                  </div>
                  <Typography style={{textAlign: 'center', fontStyle: 'italic', color: COLORS.mainColor}}>J’augmente mon audience auprès des consommateurs et deviens visible depuis toute ma ville</Typography>
                </Grid>

                <Grid style={{alignItems: 'center', justifyContent: 'center'}} item xs={6} sm={6} md={2}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '80%'}} alt="vignette2" src={Vignette2} />
                  </div>
                  <Typography style={{textAlign: 'center', fontStyle: 'italic', color: COLORS.mainColor}}>Je touche un public citoyen, sensible aux achats auprès des petits commerces</Typography>
                </Grid>

                <Grid style={{alignItems: 'center', justifyContent: 'center'}} item xs={6} sm={6} md={2}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '80%'}} alt="vignette3" src={Vignette3} />
                  </div>
                  <Typography style={{textAlign: 'center', fontStyle: 'italic', color: COLORS.mainColor}}>J’élargis ma vitrine et mes heures d’ouverture sans une minute de travail en plus</Typography>
                </Grid>

                <Grid style={{alignItems: 'center', justifyContent: 'center'}} item xs={6} sm={6} md={2}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '80%'}} alt="vignette4" src={Vignette4} />
                  </div>
                  <Typography style={{textAlign: 'center', fontStyle: 'italic', color: COLORS.mainColor}}>J’adopte une solution qui concilie astucieusement la tradition et la modernité</Typography>
                </Grid>

                <Grid style={{alignItems: 'center', justifyContent: 'center'}} item xs={6} sm={6} md={2}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '80%'}} alt="vignette5" src={Vignette5} />
                  </div>
                  <Typography style={{textAlign: 'center', fontStyle: 'italic', color: COLORS.mainColor}}>Je profite d’un nouveau tremplin pour annoncer mes actions et mes promotions</Typography>
                </Grid>

                <Grid style={{alignItems: 'center', justifyContent: 'center'}} item xs={6} sm={6} md={2}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img style={{width: '80%'}} alt="vignette6" src={Vignette6} />
                  </div>
                  <Typography style={{textAlign: 'center', fontStyle: 'italic', color: COLORS.mainColor}}>Je découvre des options digitales qui peuvent sérieusement m’aider côté gestion.</Typography>
                </Grid>

              </Grid>

            </Grid>

            <Grid item xs={0} sm={0} md={1}></Grid>
            
          </Grid>



          <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 60, paddingBottom: 10}}>

            <Typography variant="h4" style={{textAlign: 'center', color: COLORS.secondaryColor}}>Voilà pour l’essentiel, soldat Ryan !</Typography>

          </Grid>
          <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white'}}>
            <Typography variant="subtitle1" style={{textAlign: 'center', color: COLORS.mainColor}}>Maintenant, c’est à vous de jouer.</Typography>
          </Grid>
          <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white'}}>
            <Typography variant="subtitle1" style={{textAlign: 'center', color: COLORS.mainColor}}>Pour remettre le commerce à taille humaine sur le devant</Typography>
          </Grid>          
          <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white'}}>
            <Typography variant="subtitle1" style={{textAlign: 'center', color: COLORS.mainColor}}>de la scène, vous n’avez qu’une décision à prendre :</Typography>
          </Grid>


          <Grid container style={{paddingTop: 60, paddingBottom: 60, backgroundColor: 'white'}}>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}  container xs={10} sm={10} md={6}>
              <Typography align="center" variant="h4" style={{color: COLORS.mainColor}}>Rejoindre les <span style={{color: COLORS.secondaryColor}}>Q</span>ommerçants du <span style={{color: COLORS.secondaryColor}}>Q</span>oin de la rue sur <span style={{color: COLORS.secondaryColor}}>Q</span>orner.</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
          </Grid>


          <Grid container xs={12} style={{paddingBottom: 60, backgroundColor: 'white'}}>
            <Grid item xs={0} sm={0} md={3}>
            </Grid>
            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}  container xs={0} sm={0} md={6}>
              { button }
            </Grid>
            <Grid item xs={0} sm={0} md={3}>
            </Grid>
          </Grid>

        </Grid>
      );

  }
}

