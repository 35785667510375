import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


import './App.css';

import Logo from './seller_assets/logo.png';



const COLORS = {
  mainColor: '#002547',
  secondaryColor: '#f90000',
};



export default class CustomerPortal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nickname: "",
      shop: "",
      city: "",
      phone: "",
      mail: "",
      message: "",
      checked: false,
      valid: false,

      isSending: false,
      sent: false,
      error: false
    }
  }

  checkValidity() {
    var t = this.state.name !== ""
            && this.state.nickname !== ""
            && this.state.shop !== ""
            && this.state.city !== ""
            && this.state.phone !== ""
            && this.state.mail !== ""
            && this.state.message !== ""
            && this.state.checked;
    this.setState({valid: t})
  }

  send() {
    this.setState({isSending: true}, () => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: this.state.name,
          nickname: this.state.nickname,
          shop: this.state.shop,
          city: this.state.city,
          phone: this.state.phone,
          mail: this.state.mail,
          message: this.state.message,
          type: this.props.affiliate ? "affiliation" : "contact",
        })
      };
      fetch('https://us-central1-qorner-a8919.cloudfunctions.net/sendForm', requestOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({isSending: false, sent: true})
      })
      .catch((e) => {
        this.setState({isSending: false, sent: true, error: true})
      })

    })
  }

  render() {

      return (
        <Grid container>


          <Grid style={{backgroundColor: COLORS.mainColor}} container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid item xs={11} sm={11} md={8}>
              <img style={{maxWidth: '100%'}} alt="logo" src={Logo} />
            </Grid>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

          </Grid>

          <Grid style={{backgroundColor: COLORS.mainColor}} container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid style={{display: 'flex', justifyContent: 'flex-end'}} item xs={11} sm={11} md={8}>
              <Typography variant="h3" style={{fontWeight: 'bold', color: 'white', fontFamily: "Montserrat"}}>{this.props.affiliate ? "Demande d’affiliation" : "Formulaire de Contact"}</Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

          </Grid>

          <Grid style={{height: 50}} container>
          </Grid>

          <Grid container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid item xs={11} sm={11} md={8}>
              <Typography variant="description" style={{color: 'black', fontFamily: "Montserrat"}}>{!this.props.affiliate ? "Vous êtes commerçant ? Artisan ? Pour toute demande d’information à propos de la visibilité que QORNER peut apporter à votre activité, le formulaire est là pour que notre équipe puisse prendre contact avec vous :" : "Vous êtes commerçant, artisan, convaincu de la visibilité que QORNER peut vous apporter, notre équipe d’affiliation vous contactera rapidement après réception de ces quelques informations :" }</Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

          </Grid>

          <Grid style={{height: 10}} container>
          </Grid>

          <Grid container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid item xs={11} sm={11} md={8}>

              <TextField style={{width: '100%', marginBottom: 10}} value={this.state.name} onChange={(event) => this.setState({name: event.target.value}, () => this.checkValidity() )} label="Nom*" variant="standard" />
              <TextField style={{width: '100%', marginBottom: 10}} value={this.state.nickname} onChange={(event) => this.setState({nickname: event.target.value}, () => this.checkValidity() )} label="Prénom*" variant="standard" />
              <TextField style={{width: '100%', marginBottom: 10}} value={this.state.shop} onChange={(event) => this.setState({shop: event.target.value}, () => this.checkValidity() )} label="Enseigne*" variant="standard" />
              <TextField style={{width: '100%', marginBottom: 20}} value={this.state.city} onChange={(event) => this.setState({city: event.target.value}, () => this.checkValidity() )} label="Ville*" variant="standard" />
              <Typography variant="description" style={{color: 'black', fontFamily: "Montserrat"}}>Et pour me joindre :</Typography>
              <TextField style={{width: '100%', marginBottom: 10}} value={this.state.phone} onChange={(event) => this.setState({phone: event.target.value}, () => this.checkValidity() )} label="Téléphone*" variant="standard" />
              <TextField style={{width: '100%', marginBottom: 10}} value={this.state.mail} onChange={(event) => this.setState({mail: event.target.value}, () => this.checkValidity() )} label="Adresse mail*" variant="standard" />
              <TextField multiline rows={6} style={{width: '100%', marginBottom: 10}} value={this.state.message} onChange={(event) => this.setState({message: event.target.value}, () => this.checkValidity() )} label="Message*" variant="standard" />
              <FormControlLabel control={<Checkbox checked={this.state.checked} onChange={(event) => this.setState({checked: event.target.checked}, () => this.checkValidity() ) } defaultChecked={false} />} label="*En soumettant ce formulaire, vous acceptez que les informations saisies soient traitées pour permettre de vous recontacter. Pour connaître et exercer vos droits, notamment de retrait de votre consentement à l'utilisation des données collectées par ce formulaire, veuillez consulter notre politique de confidentialité ci-dessous." />
              <br />
              <br />
              <br />
              {this.state.isSending ? <CircularProgress /> : (this.state.sent ? <Button style={{backgroundColor: this.state.error ? 'tomato' : 'green', paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10, color: 'white', fontWeight: 'bold', fontFamily: "Montserrat"}} variant="contained">{this.state.error ? "Erreur" : "ENVOYé, merci"}</Button> : <Button disabled={!this.state.valid} onClick={() => this.state.valid ? this.send() : null} style={{backgroundColor: !this.state.valid ? 'grey' : COLORS.mainColor, paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10, color: 'white', fontWeight: 'bold', fontFamily: "Montserrat"}} variant="contained">ENVOYER</Button>) }
              <br />
              { this.state.sent && !this.state.error ? <Typography variant="caption" style={{color: 'black', fontFamily: "Montserrat"}}>On revient vers vous dans les plus brefs délais !</Typography> : null }
              <br />
              <br />
              <br />
              <Typography variant="caption" style={{color: 'black', fontFamily: "Montserrat"}}>Les informations recueillies sur ce formulaire sont enregistrées par QORNER dans un fichier informatisé, sur la base de votre consentement. L'objectif du recueil de ces informations est de pouvoir vous contacter exclusivement au sujet du service proposé par QORNER. Les données collectées seront communiquées aux seuls destinataires suivants : QORNER.</Typography>
              <br />
              <Typography variant="caption" style={{color: 'black', fontFamily: "Montserrat"}}>Les données sont conservées pendant 12 mois ou pendant la durée de la relation commerciale, le cas échéant. Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation de leur traitement. Vous pouvez retirer à tout moment votre consentement au traitement de vos données. Consultez le site cnil.fr pour plus d’informations sur vos droits.</Typography>
              <br />
              <br />
              <Typography variant="caption" style={{color: 'black', fontFamily: "Montserrat"}}>Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter : contact@qorner.com.</Typography>
              <br />
              <br />
              <Typography variant="caption" style={{color: 'black', fontFamily: "Montserrat"}}>Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.</Typography>
              <br />
              <br />
              <br />
              <br />
            </Grid>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

          </Grid>



        </Grid>
      );

  }
}



