import React, { Component } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Customer from './Customer';
import Seller from './Seller';
import Contact from './Contact';
import './App.css';
//affiliate


export default class AppComponent extends Component {


  render() {

      return (
        <BrowserRouter>
          <Routes>
            <Route path="*" exact={false} element={<Customer />} />
            <Route path="/commerce" exact={false} element={<Seller />} />
            <Route path="/contact" exact={false} element={<Contact />} />
            <Route path="/register" exact={false} element={<Contact affiliate={true} />} />
          </Routes>
        </BrowserRouter>
      );

  }
}

