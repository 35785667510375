import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './App.css';

import Arc from './customer_assets/arc.jpg';
import Img1 from './customer_assets/img1.png';
import Img2 from './customer_assets/img2.png';
import Img3 from './customer_assets/img3.png';
import Img4 from './customer_assets/img4.png';
import Img5 from './customer_assets/img5.png';
import Img6 from './customer_assets/img6.png';
import Smiley from './customer_assets/smiley.png';

const COLORS = {
  mainColor: '#002547',
  secondaryColor: '#f90000',
};


const button = <div onClick={() => window.location.href="http://qrco.de/qornerapp"} className="buttonContainer" style={{marginTop: 30, backgroundColor: 'white', paddingRight: 24, paddingLeft: 24, paddingTop: 12, paddingBottom: 12, borderRadius: 200}}> <a href="http://qrco.de/qornerapp" style={{textTransform: 'uppercase', textDecoration: 'none', fontWeight: 'bold', color: COLORS.secondaryColor}}>Je télécharge Qorner !</a></div>;
const button2 = <div onClick={() => window.location.href="http://qrco.de/qornerapp"} className="buttonContainer" style={{backgroundColor: 'white', paddingRight: 30, paddingLeft: 30, paddingTop: 15, paddingBottom: 15, borderRadius: 200}}> <a href="http://qrco.de/qornerapp" style={{textTransform: 'uppercase', textDecoration: 'none', fontWeight: 'bold', color: COLORS.secondaryColor}}>Je télécharge Qorner !</a></div>;
const button3 = <div onClick={() => window.location.href="http://qorner.com/commerce"} className="buttonContainer" style={{border: "2px white solid", backgroundColor: COLORS.secondaryColor, paddingRight: 30, paddingLeft: 30, paddingTop: 15, paddingBottom: 15, borderRadius: 200}}> <a href="http://qorner.com/commerce" style={{textTransform: 'uppercase', textDecoration: 'none', fontWeight: 'bold', color: 'white'}}>Découvrir comment Qorner aide les petits commerçants !</a></div>;
const button4 = <div onClick={() => window.location.href="http://qorner.com/commerce"} className="buttonContainer3" style={{ marginTop: 30, border: "2px white solid", backgroundColor: COLORS.secondaryColor, paddingLeft: 22, paddingRight: 22, paddingTop: 12, paddingBottom: 12, borderRadius: 200}}> <a href="http://qorner.com/commerce" style={{textTransform: 'uppercase', textDecoration: 'none', fontWeight: 'bold', color: 'white'}}>COMMERÇANTS, ARTISANS C'EST PAR ICI !</a></div>;


export default class CustomerPortal extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }


  componentDidMount() {
  }

  render() {


      return (
        <Grid container>

          <Grid className="mainDiv" container md={12} style={{backgroundImage: `url(${Arc})`,  backgroundSize: 'cover', paddingBottom: 120, paddingTop: 60}}>
            
            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid item xs={11} sm={11} md={6}>
              <Typography variant="h3" style={{fontWeight: 'bold', color: 'white'}}>Retrouvez le plaisir attachant des petits magasins et consommez malin !</Typography>
              <Grid container md={12}>
                <Grid item xs={11} sm={11} md={7}>
                  <br />
                  <Typography variant="subtitle1" align="justify" style={{fontStyle: 'italic', color: 'white'}}>Avec Qorner, je gagne un temps fou : je mets directement la main sur ce que je cherche, avec le sourire en prime !</Typography>
                  <br />
                  <Typography variant="subtitle2" align="justify" style={{color: 'white'}}>Qorner veut <span style={{fontWeight: 'bold'}}>remettre du bon sens dans l’acte d’achat</span> en soulignant ce qui a toujours fait la force des petits commerces : l’accueil, la tradition, le sourire, le service, les conseils. Sans oublier le choix et la disponibilité, dans tous les sens du mot.</Typography>
                  <br />
                  <Typography variant="subtitle2" align="justify" style={{color: 'white'}}>Si ces valeurs vous tiennent à cœur, bienvenue dans la tribu ! Vous n’avez qu’un geste à faire : <span style={{fontWeight: 'bold'}}>télécharger Qorner !</span></Typography>
                  <br />
                  <Typography variant="caption" align="justify" style={{fontWeight: 'bold', color: 'white'}}>C’est totalement gratuit et sans le moindre engagement.</Typography>
                  <div style={{height: 50}}></div>
                  <p></p>
                  { button }
                  <br />
                  <div style={{display: "inline-block"}}>
                    { button4 }
                  </div>
                </Grid>
                <Grid item xs={1} sm={1} md={5}>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={0} sm={0} md={4}>
            </Grid>

          </Grid>

          <Grid container>

            <Grid item xs={1} sm={1} md={2}>
            </Grid>

            <Grid container xs={10} sm={10} md={4} style={{zIndex: 2, paddingTop: 60, paddingBottom: 40, justifyContent: 'flex-end'}}>
              <Grid item xs={12}>
                <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Qorner, <span style={{color: COLORS.secondaryColor}}>comment ça marche</span> en pratique ?</Typography>
              </Grid>
              <Grid item xs={12}>
                <br />
                <Typography align="justify" variant="subtitle2">Pour rejoindre la communauté Qorner, c’est très simple : il suffit de <span style={{fontWeight: 'bold'}}>télécharger l’application</span> et de <span style={{fontWeight: 'bold'}}>créer votre compte</span> via votre identifiant Google, Facebook ou <span style={{fontWeight: 'bold'}}>une adresse mail</span> et <span style={{fontWeight: 'bold'}}>un mot de passe.</span></Typography>
                <br />
                <Typography align="justify" variant="subtitle2">À partir de là, vous aurez accès à l’ensemble des services proposés par l’application. Grâce à la <span style={{fontWeight: 'bold'}}>géolocalisation</span>, vous aurez vite fait de repérer les commerces qui sont proches de l’endroit où vous vous trouvez, que ce soit votre <span style={{fontWeight: 'bold'}}>domicile</span>, votre <span style={{fontWeight: 'bold'}}>lieu de travail</span> ou l’<span style={{fontWeight: 'bold'}}>endroit inconnu</span> vers lequel vous vous dirigez en ce moment précis !</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">Qorner vous épaule à tout moment, que ce soit dans votre canapé pour préparer un achat en sirotant votre café ou à l’instant T, sur la route, à l’endroit où vous êtes. Le mieux du mieux, c’est que l’application vous permet de « voir » tous les produits disponibles en <span style={{fontWeight: 'bold'}}>image</span>, avec toutes les <span style={{fontWeight: 'bold'}}>infos utiles</span> : quel prix, quelle taille, quelle couleur, etc.</Typography>
                <br />
                <Typography align="justify" variant="subtitle2">En complément, la <span style={{fontWeight: 'bold'}}>messagerie intégrée</span> vous offre aussi l’opportunité d’envoyer un petit mot au commerçant avant de vous rendre dans sa <span style={{fontWeight: 'bold'}}>boutique</span> pour effectuer votre achat !</Typography>
                <br />
              </Grid>
              <Grid item xs={0}>
              </Grid>
            </Grid>

            <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={12} sm={12} md={3}>
              <img style={{height: '90%', position: 'relative', top: -120}} alt="phone1" src={Img1} />
            </Grid>

            <Grid item xs={0} sm={0} md={3}>
            </Grid>

          </Grid>



          <Grid container style={{paddingTop: 100, backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={0} sm={0} md={2}>
            </Grid>

            <Grid item xs={12} sm={12} md={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img style={{width: '100%'}} alt="phone2" src={Img2} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>

                <Grid container>

                  <Grid item xs={1} sm={1} md={0}></Grid>

                  <Grid item xs={10} sm={10} md={8}>
                    <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Comment Qorner peut me <span style={{color: COLORS.secondaryColor}}>simplifier la vie</span> ?</Typography>
                    <div>
                      <br />
                      <Typography align="justify" variant="subtitle2">Avec Qorner sur votre smartphone, vous aurez toujours les <span style={{fontWeight: 'bold'}}>bonnes adresses</span> à portée de main, pour des achats <span style={{fontWeight: 'bold'}}>produits</span> et <span style={{fontWeight: 'bold'}}>services</span> de toute nature : vêtements, décoration, épicerie fine, salon de beauté, fleuriste, cadeaux, toilettage, etc.</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">L’appli vous servira en toutes circonstances, tant pour les achats « besoin » auxquels vous pensiez depuis longtemps que pour les <span style={{fontWeight: 'bold'}}>envies subites</span> et les surprises en <span style={{fontWeight: 'bold'}}>last minute</span>.</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Tilt, ça vous revient ! Pas plus tard qu’hier, vous avez promis à Claire et Louise de les accompagner samedi pour un jogging du côté de Vincennes ... sauf que vous n’avez pas les bonnes chaussures. En un coup d’œil, Qorner vous dira où trouver et essayer la paire de running idéale, sans y laisser vos semelles ni votre souffle !</Typography>
                      <br />
                      <br />
                      <Typography align="center" variant="body1">Vous pourrez même envoyer un smiley au commerçant pour qu’il vous la mette de côté.<img alt="smiley" style={{paddingLeft: 10, width: 15}} src={Smiley} /></Typography>
                    </div>
                  </Grid>

                  <Grid item xs={1} sm={1} md={4}></Grid>

                </Grid>

            </Grid>

          </Grid>




          <Grid container style={{paddingBottom: 20, paddingTop: 60, backgroundColor: 'rgb(243, 242, 242)'}}>

            <Grid item xs={0} sm={0} md={2}>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Typography style={{marginBottom: 10, padding: 20, }} align="justify" variant="subtitle2">Trois semaines plus tard, vous roulez dans Paris pour retrouver Lucas, un pote de fac que vous n’avez plus vu depuis 10 ans. Un lointain souvenir vous revient : c’est un grand fan de cuisine. Sur l’itinéraire qui vous guide, faites confiance à Qorner pour vous renseigner l’adresse de la petite librairie où vous trouverez le livre gourmand qui s’arrache. Et l’occasion faisant le larron, vous craquerez immanquablement pour le dernier Connelly, normal, c’est trop bon !</Typography>
            </Grid>

            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} item xs={12} sm={12} md={4}>
              { button2 }
            </Grid>

            <Grid item xs={0} sm={0} md={2}>
            </Grid>

          </Grid>



          <Grid container style={{paddingTop: 30, paddingBottom: 100, backgroundColor: 'rgb(243, 242, 242)'}}>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
            <Grid item xs={10} sm={10} md={6}>
              <Typography align="center" variant="body1">Comme le démontrent ces deux petits exemples, Qorner deviendra très vite un complice incontournable. Non contente de vous aider chaque fois que vous en aurez besoin, l’application vous fera <span style={{fontWeight: 'bold'}}>découvrir des petits commerçants</span> que vous ne connaissiez pas ! Certains d’entre eux entreront immanquablement dans votre <span style={{fontWeight: 'bold'}}>liste de favoris</span>. Vous recevrez alors toutes les <span style={{fontWeight: 'bold'}}>notifications</span> qui les concernent : journée portes ouvertes, promotion spéciale, anniversaire, changement d’horaire, etc.</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{backgroundColor: 'white'}}>
            <Grid item xs={0} sm={0} md={2}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={{zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img style={{height: '110%'}} alt="colis" src={Img3} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{zIndex: 2, paddingTop: 60}}>

                <Grid container>

                  <Grid item xs={1} sm={1} md={0}></Grid>

                  <Grid item xs={10} sm={10} md={8}>
                    <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>La guerre des colis, <span style={{color: COLORS.secondaryColor}}>je m’en passe</span> volontiers !</Typography>
                    <div style={{marginBottom: 20}}>
                      <br />
                      <Typography align="justify" variant="subtitle2">Avec Qorner, vous n’aurez plus besoin d’attendre votre livraison sur le pas de la porte en vous demandant si ce qu’il y a dans le colis correspond bien à vos attentes.</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Ce n’est pas le bon livre ? Le pull est trop petit ? La moitié du flacon s’est évaporée ?</Typography>
                      <Typography align="justify" variant="subtitle2">Tous ces incidents de parcours qui vous obligent à rédiger des bordereaux - et à faire le pied de grue à la poste - pour remballer ce qui ne convient pas, oubliez-les !</Typography>
                      <br />
                      <Typography align="justify" variant="subtitle2">Dans la boutique de votre commerçant, tout est plus clair ! Si la taille vous convient et si la couleur vous plaît, vous pouvez craquer. Et si l’article ne correspond pas vraiment à ce que vous espériez, ce n’est pas grave. Vous repasserez une autre fois et le commerçant sera ravi de vous revoir.</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={1} sm={1} md={4}></Grid>

                </Grid>

            </Grid>

          </Grid>


          <Grid container xs={12} style={{paddingTop: 40, paddingBottom: 60, backgroundColor: 'rgb(243, 242, 242)'}}>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
            <Grid container xs={10} sm={10} md={6}>
              <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Quelle est la <span style={{color: COLORS.secondaryColor}}>zone géographique couverte</span> par Qorner ?</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
            <Grid container>
              <Grid item xs={0} sm={0} md={3}>
              </Grid>
              <Grid style={{padding: 20, backgroundImage: `url(${Img4})`, backgroundSize: '25%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', display: 'flex',  justifyContent:'center', alignItems:'center'}} item xs={12} sm={12} md={3}>
                <Typography align="justify" variant="subtitle2">Pour sa première année (2022), l’application Qorner se concentre sur Paris. Dès l’année suivante, l’application s’étendra aux agglomérations de la 1ère couronne et aux grandes métropoles : Lyon, Marseille, Lille, Bordeaux, Nantes.</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <img style={{width: '100%'}} alt="phone1" src={Img5} />
              </Grid>
              <Grid item xs={0} sm={0} md={2}>
              </Grid>
            </Grid>
          </Grid>


          <Grid container xs={12} style={{paddingTop: 60, paddingBottom: 60, backgroundColor: 'white'}}>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} container item xs={10} sm={10} md={6}>
              <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Que doivent faire les commerçants pour <span style={{color: COLORS.secondaryColor}}>s’inscrire</span> ?</Typography>
              <Typography style={{paddingTop: 30}} align="center" variant="subtitle2">À l’image de cette page destinée aux <span style={{fontWeight: 'bold'}}>consommateurs</span>, il existe une page Qorner dédiée aux <span style={{fontWeight: 'bold'}}>petits commerçants</span>. Ils y trouveront toutes les <span style={{fontWeight: 'bold'}}>infos utiles</span> pour <span style={{fontWeight: 'bold'}}>répertorier leur commerce</span> dans l’application afin d’élargir leur clientèle.</Typography>
              <Typography style={{paddingBottom: 30}} align="center" variant="subtitle2">Si vous avez des <span style={{fontWeight: 'bold'}}>petits commerçants</span> dans <span style={{fontWeight: 'bold'}}>vos connaissances</span>, n’hésitez surtout pas à leur communiquer le lien ci-dessous. Vous leur rendrez un fier service.</Typography>
              { button3 }
            </Grid>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
          </Grid>


          <Grid container style={{paddingTop: 60, paddingBottom: 30, backgroundColor: 'rgb(243, 242, 242)'}}>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} container xs={10} sm={10} md={6}>
              <Typography variant="h5" style={{fontWeight: 'bold', color: COLORS.mainColor}}>Un petit <span style={{color: COLORS.secondaryColor}}>résumé</span> pour conclure !</Typography>
              <Typography style={{paddingTop: 20, paddingBottom: 30}} align="left" variant="subtitle2">Avec l’application Qorner dans ma boîte magique, je gagne le tiercé dans l’ordre, gratuitement et sans engagement :</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
          </Grid>

          <Grid container style={{backgroundColor: 'rgb(243, 242, 242)'}}>
            <Grid item xs={0} sm={0} md={3}>
            </Grid>
            <Grid container xs={12} sm={12} md={6}>
              <Grid item xs={4}>
                <img style={{width: '100%'}} alt="resume" src={Img6} />
              </Grid>
              <Grid container xs={8} style={{paddingLeft: 10, display: 'flex',  justifyContent:'center', alignItems:'center'}}>

                  <Typography align="center" variant="subtitle2">Je gagne un temps fou en mettant directement la main sur tout ce que j’achète, avec le sourire en prime !</Typography>
                
                  <Typography align="center" variant="subtitle2">Je ne me casse plus la tête - ni les ongles - avec des cartons que je dois remballer deux fois sur cinq.</Typography>

                  <Typography align="center" variant="subtitle2">Je pose un geste clair en faveur des petits commerçants et j’encourage les magasins de proximité.</Typography>

              </Grid>
            </Grid>
            <Grid item xs={0} sm={0} md={3}>
            </Grid>
          </Grid>

          <Grid container style={{paddingTop: 30, paddingBottom: 60, backgroundColor: 'rgb(243, 242, 242)'}}>
            <Grid item xs={1} sm={1} md={4}>
            </Grid>
            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}} container xs={10} sm={10} md={4}>
              <Typography style={{paddingBottom: 20}} align="center" variant="subtitle2">Au fil de vos achats, Qorner vous promet de jolies rencontres. Oui, vous allez retrouver - et savourer - <span style={{fontWeight: 'bold'}}>le plaisir attachant des petits magasins.</span></Typography>
              <Typography align="center" variant="subtitle2">Dans un monde qui galope toujours plus vite, Qorner a choisi sa mission : favoriser les achats locaux et remettre <span style={{fontWeight: 'bold'}}>le commerce à taille humaine</span> sur le devant de la scène.</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={4}>
            </Grid>
          </Grid>


          <Grid container style={{paddingBottom: 60, backgroundColor: 'rgb(243, 242, 242)'}}>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}  container xs={10} sm={10} md={6}>
              <Typography align="center" variant="h4" style={{color: COLORS.mainColor}}><span style={{color: COLORS.secondaryColor}}>Q</span>u’on se le dise : les <span style={{color: COLORS.secondaryColor}}>Q</span>ommerçants du <span style={{color: COLORS.secondaryColor}}>Q</span>oin de la rue sont sur <span style={{color: COLORS.secondaryColor}}>Q</span>orner.</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3}>
            </Grid>
          </Grid>


          <Grid container xs={12} style={{paddingBottom: 60, backgroundColor: 'rgb(243, 242, 242)'}}>
            <Grid item xs={0} sm={0} md={3}>
            </Grid>
            <Grid style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}  container xs={0} sm={0} md={6}>
              { button }
            </Grid>
            <Grid item xs={0} sm={0} md={3}>
            </Grid>
          </Grid>

        </Grid>
      );

  }
}

